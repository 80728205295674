.error-wrapper {
    width: 70rem;
    text-align: center;
    padding: 2rem;
}

.error-text { 
    font-size: 1.7rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 400;
    color: var(--primary-grey);
    margin-bottom: 3rem;
}

.error-btn {
    font-size: 1.7rem;
    font-weight: 600;
    width: 20rem;
    padding: 1rem 0;
    color: white;
    background-color: var(--primary-yellow);
    border: none;
    border-radius: 30px;
    cursor: pointer;
}